var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wap-category-list",class:_vm.showPopup ? 'show' : ''},[_c('div',{staticClass:"top flex-center-between"},[_c('div',{staticClass:"flex-center"},[_c('img',{attrs:{"src":_vm.categoryUrl,"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("shai-xuan"))+" ")]),_c('div',[_c('i',{staticClass:"el-icon-close",on:{"click":_vm.cancel}})])]),_c('div',{staticClass:"category-main"},[_c('div',{staticClass:"list",on:{"click":_vm.showAll}},[_c('div',{staticClass:"label",class:_vm.form.category == '' ? 'active' : ''},[_vm._v(" "+_vm._s(_vm.$t("quan-bu-shang-pin"))+" ")])]),_vm._l((_vm.categoryList),function(item,i){return _c('div',{key:i,staticClass:"list"},[_c('div',{staticClass:"label flex-center-between",on:{"click":function($event){return _vm.changeShow(item, i)}}},[_vm._v(" "+_vm._s(item.title)+" "),(item.children && item.children.length > 0 && !item.show)?_c('i',{staticClass:"el-icon-arrow-right"}):_vm._e(),(item.children && item.children.length > 0 && item.show)?_c('i',{staticClass:"el-icon-arrow-down"}):_vm._e()]),_c('div',{staticClass:"child-box",class:item.show ? 'show' : ''},_vm._l((item.children),function(subItem,index){return _c('div',{key:index,staticClass:"sub-list"},[_c('div',{staticClass:"sub-label flex-center-between",on:{"click":function($event){return _vm.changeShowSub(subItem, i, index)}}},[_vm._v(" "+_vm._s(subItem.title)+" "),(
                subItem.children &&
                subItem.children.length > 0 &&
                !subItem.show
              )?_c('i',{staticClass:"el-icon-arrow-right"}):_vm._e(),(
                subItem.children &&
                subItem.children.length > 0 &&
                subItem.show
              )?_c('i',{staticClass:"el-icon-arrow-down"}):_vm._e()]),_c('div',{staticClass:"category-box",class:subItem.show ? 'show' : ''},_vm._l((subItem.children),function(category,m){return _c('div',{key:m,staticClass:"category",class:_vm.form.category == category.ID ? 'active' : '',on:{"click":function($event){return _vm.chooseCategory(category)}}},[_vm._v(" "+_vm._s(category.title)+" ")])}),0)])}),0)])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
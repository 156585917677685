<template>
  <div class="wap-category-list" :class="showPopup ? 'show' : ''">
    <div class="top flex-center-between">
      <div class="flex-center">
        <img :src="categoryUrl" alt="" />
        {{ $t("shai-xuan") }}
      </div>
      <div>
        <i class="el-icon-close" @click="cancel"></i>
      </div>
    </div>

    <div class="category-main">
      <div class="list" @click="showAll">
        <div class="label" :class="form.category == '' ? 'active' : ''">
          {{ $t("quan-bu-shang-pin") }}
        </div>
      </div>
      <div class="list" v-for="(item, i) in categoryList" :key="i">
        <div class="label flex-center-between" @click="changeShow(item, i)">
          {{ item.title }}
          <i
            class="el-icon-arrow-right"
            v-if="item.children && item.children.length > 0 && !item.show"
          ></i>
          <i
            class="el-icon-arrow-down"
            v-if="item.children && item.children.length > 0 && item.show"
          ></i>
        </div>
        <div class="child-box" :class="item.show ? 'show' : ''">
          <div
            class="sub-list"
            v-for="(subItem, index) in item.children"
            :key="index"
          >
            <div
              class="sub-label flex-center-between"
              @click="changeShowSub(subItem, i, index)"
            >
              {{ subItem.title }}
              <i
                class="el-icon-arrow-right"
                v-if="
                  subItem.children &&
                  subItem.children.length > 0 &&
                  !subItem.show
                "
              ></i>
              <i
                class="el-icon-arrow-down"
                v-if="
                  subItem.children &&
                  subItem.children.length > 0 &&
                  subItem.show
                "
              ></i>
            </div>

            <div class="category-box" :class="subItem.show ? 'show' : ''">
              <div
                class="category"
                v-for="(category, m) in subItem.children"
                :key="m"
                :class="form.category == category.ID ? 'active' : ''"
                @click="chooseCategory(category)"
              >
                {{ category.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      categoryUrl: require('@/assets/imgs/icon-category.png'),
      form: {
        category: ''
      }
    }
  },
  props: {
    showPopup: Boolean
  },
  computed: {
    categoryList() {
      return this.$store.state.categoryList
    }
  },
  methods: {
    changeShow(data, i) {
      let show = !data.show
      this.$set(this.categoryList[i], 'show', show)
      this.form.category = data.ID
    },
    changeShowSub(data, i, index) {
      if (!data.children || data.children.length == 0) {
        return
      }
      let show = !data.show
      this.$set(this.categoryList[i].children[index], 'show', show)
      this.form.category = data.ID
    },
    chooseCategory(data) {
      this.form.category = data.ID
      this.$emit('submit', this.form.category)
    },
    showAll() {
      this.form.category = ''
      this.$emit('submit', this.form.category)
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>